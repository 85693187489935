.clock {
    z-index: 99;
}

.clock-bg {
    background-color: rgb(252, 252, 252);
}

.clock-font {
    font-size: 18px;
    color: grey;
    font-weight: 500;
}