.mission {
    z-index: 99;
    top: 10%;
}

.wifi-icon {
    width: 18px;
    height: 18px;
}

@media screen and (max-width: 576px) {
    .mission {
        display: none;
    }
}

.mission-box {
    background-color: rgba(0, 0, 0, 0.5);
    height: auto;
    max-height: 40vh;
    overflow: auto;
    box-shadow: #888 0px 0px 10px;
}

/* For WebKit browsers (Chrome, Safari) */
.mission-box::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.mission-box::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Set the color of the scrollbar thumb */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /* Set the border-radius for the thumb */
}

.mission-box::-webkit-scrollbar-track {
    background-color: rgb(0, 0, 0, 0.1);
    /* Set the color of the scrollbar track */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /* Set the border-radius for the track */
}

/* For Firefox */
.mission-box {
    scrollbar-width: thin;
    /* Set the width of the scrollbar */
    scrollbar-color: #888 rgb(0, 0, 0, 0.1);
    /* Set the color of the scrollbar thumb and track */
}

@media (max-width: 992px) {
    .mission-box {
        max-height: 24vh !important;
    }
}