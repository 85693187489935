.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
  
.loader {
  border: 3px solid var(--color-main);
  border-top: 3px solid var(--color-grey);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  