.slider-container {
    height: 150px;
    overflow: hidden;
}
  
.custom-arrow {
    width: 30px;
    height: 30px;
    background-color: transparent;
    color: grey;
    font-size: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
}
  
.next-arrow {
    right: 0px;
}
  
.prev-arrow {
    left: 0px;
}