.donut-chart {
    width: 100%;
}

.donut-chart svg {
    height: 100%;
}

@media (min-width: 361px) {
    .donut-chart {
        width: 80%;
    }
}

@media (min-width: 525px) {
    .donut-chart {
        width: 60%;
    }
}

@media (min-width: 575px) {
    .donut-chart {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .donut-chart {
        width: 70%;
    }
}

@media (min-width: 992px) {
    .donut-chart {
        width: 60%;
    }
}

@media (min-width: 1199px) {
    .donut-chart {
        width: 100%;
    }
}

.bar-chart {
    width: 90%;
}

@media (min-width: 435px) {
    .bar-chart {
        width: 75%;
    }
}

@media (min-width: 485px) {
    .bar-chart {
        width: 65%;
    }
}

@media (min-width: 525px) {
    .bar-chart {
        width: 60%;
    }
}

@media (min-width: 575px) {
    .bar-chart {
        width: 85%;
    }
}

@media (min-width: 695px) {
    .bar-chart {
        width: 75%;
    }
}

@media (min-width: 768px) {
    .bar-chart {
        width: 70%;
    }
}

@media (min-width: 855px) {
    .bar-chart {
        width: 60%;
    }
}

@media (min-width: 992px) {
    .bar-chart {
        width: 45%;
    }
}

@media (min-width: 1120px) {
    .bar-chart {
        width: 45%;
    }
}

@media (min-width: 1199px) {
    .bar-chart {
        width: 100%;
    }
}