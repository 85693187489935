.mapboxgl-ctrl-logo {
    display: none !important;
}
  
.mapboxgl-canvas {
    cursor: default !important;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapboxgl-popup {
    z-index: 99;
}

.mapboxgl-popup-content {
    border-radius: 5px !important;
}