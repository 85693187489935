:root {
  --font-size-base: 14px;
  --font-size-bigger: 16px;
  --font-size-smaller: 12px;

  --font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  --color-main: #52cea7;
  --color-main2: #00E396;
  --color-second: #0A79BD;
  --color-second2: #008FFB;
  --color-danger: #BD090A;
  --color-danger2: #FF4560;
  --color-grey: #f8f8f8;
}

.color-main {
  color: var(--color-main);
}

.color-main2 {
  color: var(--color-main2);
}

.color-second {
  color: var(--color-second);
}

.color-second2 {
  color: var(--color-second2);
}

.color-danger {
  color: var(--color-danger) !important;
}

.color-danger2 {
  color: var(--color-danger2) !important;
}

.color-grey {
  color: var(--color-grey);
}

.bg-main {
  background-color: var(--color-main);
}

.bg-main2 {
  background-color: var(--color-main2);
}

.bg-second {
  background-color: var(--color-second);
}

.bg-second2 {
  background-color: var(--color-second2);
}

.bg-danger {
  background-color: var(--color-danger) !important;
}

.bg-danger2 {
  background-color: var(--color-danger2) !important;
}

.bg-grey {
  background-color: var(--color-grey);
}

.bg-grey2 {
  background-color: #CCCCCC;
}

.bg-transparent {
  background-color: transparent !important;
}

.font-bigger {
  font-size: var(--font-size-bigger);
}

.font-smaller {
  font-size: var(--font-size-smaller);
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
}

.card {
  border: none;
  border-radius: 7px;
}

.card-body {
  padding: 0.75rem!important;
}

.padding-lr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.btn {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-family);
  line-height: 1;
  padding: 10px 20px;
}

.btn:focus {
  box-shadow: none;
}

.dropdown-menu {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  max-height: 550px;
  overflow-y: auto;
  background-color: white;
  cursor: pointer;
}

.dropdown-item:active {
  background-color: #f8f9fa;
}

@media (max-width: 330px) {
  .dropdown-toggle {
    padding: 10px !important;
  }
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-box {
  background-color: #d0f1e7;
  padding: 10px;
  border-radius: 10px;
}

.icon-box img {
  height: 20px;
  width: 20px;
}

.auto-height-textarea {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0.25rem;
}

.grey-textarea {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  overflow: auto;
  background-color: var(--color-grey);
  padding: 0.5rem;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-grow-1 {
  flex-grow: 1;
}

.overflow-y-auto {
  overflow-y: auto;
}

.title-font {
  font-weight: 500 !important;
}

.value-font {
  color: grey !important;
}

@media (max-width: 1200px) {
  .vh-xl-70 {
    height: 70vh !important;
  }
}

@media (max-width: 992px) {
  .flex-lg-2 {
    flex: 2 !important;
  }
}

.max-height-100 {
  max-height: 100% !important;
}

.max-height-lg-100 {
  max-height: 100% !important;
}

@media (max-width: 992px) {
  .max-height-lg-100 {
    max-height: 130px !important;
  }
}

.text-light-grey {
  color: lightgrey;
}

/* Scrollbar Settings */
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Change as needed */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9f9f9f; /* Change as needed */
  border-radius: 20px; /* Adjust the border radius for rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; /* Change as needed */
}

/* Width and Height of the scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
  height: 6px; /* Adjust the height as needed */
}
/* Scrollbar Settings End */