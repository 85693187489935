.dropdown-menu {
    margin-top: 0.75rem;
    font-size: 0.875rem;
    max-height: 550px;
    overflow-y: auto;
    background-color: white;
    cursor: pointer;
}
  
.dropdown-item:active {
    background-color: var(--color-grey);
}
  
@media (max-width: 330px) {
    .dropdown-toggle {
        padding: 10px !important;
    }
}